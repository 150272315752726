import { Button } from "@lya-protect/lya-protect-form-library/dist/UI";
import { ReactComponent as XLg } from "@src/assets/icons/x-lg.svg";
import { ReactComponent as Loader } from "@src/assets/loader.svg";
import { ID_FORM_SOCIETE_INFORMATION } from "./SocieteInformation";
import styles from "./SocieteInformationActions.module.scss";

type SocieteInformationActionsProps = {
  isLoading: boolean;
  onCancel: () => void;
};
export function SocieteInformationActions({
  isLoading,
  onCancel,
}: SocieteInformationActionsProps) {
  return (
    <div className={styles.actions}>
      <Button onClick={onCancel} variant="text" icon={<XLg />}>
        Annuler les modifications
      </Button>
      <Button
        type="submit"
        form={ID_FORM_SOCIETE_INFORMATION}
        icon={isLoading ? <Loader /> : undefined}
        disabled={isLoading}
      >
        Enregistrer
      </Button>
    </div>
  );
}
