import { withLastEntryNotDisabled } from "@src/helper/object.helper";
import { isArray } from "lodash";
import { useEffect, useState } from "react";
import { AdhesionData, adhesionDataDefault } from "./AdhesionData/adhesionData";
import { getCacheFromAPI } from "./cacheAdhesion.utils";
import { AdhesionStepEnum } from "./ParcoursAdhesion.definition";

const stepsWithDisabledCache: AdhesionStepEnum[] = [
  AdhesionStepEnum.PAIEMENT_ADHESION,
];

export function useParcoursAdhesionCacheDefaults(
  connected: boolean
): [boolean, AdhesionData, AdhesionStepEnum[] | null] {
  const [fetchState, setFetchState] = useState<"IDLE" | "FETCHING" | "READY">(
    connected ? "IDLE" : "READY"
  );
  const [defaultData, setDefaultData] = useState<AdhesionData | null>(
    connected ? null : adhesionDataDefault
  );
  const [defaultHistory, setDefaultHistory] = useState<
    AdhesionStepEnum[] | null
  >(null);

  useEffect(() => {
    if (connected && fetchState === "IDLE") {
      setFetchState("FETCHING");
      getCacheFromAPI()
        .then((res) => {
          if (
            res === null ||
            !res.history ||
            !res.data ||
            !isArray(res.history)
          ) {
            setDefaultData(adhesionDataDefault);
            setFetchState("READY");
          } else {
            setDefaultData(res.data);
            setDefaultHistory(res.history);
            setFetchState("READY");
          }
        })
        .catch(() => {
          setDefaultData(adhesionDataDefault);
          setFetchState("READY");
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [connected]);
  return [
    fetchState === "READY",
    { ...(defaultData as AdhesionData), coupon: undefined },
    withLastEntryNotDisabled(defaultHistory, stepsWithDisabledCache),
  ];
}
