import {
  GatewayAdhesion,
  GatewayChangementActivite,
  GatewayEntreprise,
  GatewayPaiement,
} from "@conformite/gateway";
import {
  FieldValues,
  UseFormReturn,
} from "@lya-protect/lya-protect-form-library/dist/Exports/reactHookForm.exports";
import { Form } from "@lya-protect/lya-protect-form-library/dist/FormAPI";
import {
  Button,
  FormField,
  FormRow,
  displayErrorToast,
  displaySucessToast,
} from "@lya-protect/lya-protect-form-library/dist/UI";
import { ID_FORM_ADHESION } from "@src/adhesion/ParcoursAdhesion.definition";
import { AdhesionVirementPage } from "@src/adhesion/Steps/AdhesionPaiement/AdhesionVirementPage";
import { GatewayChangementActiviteAPI } from "@src/api/changement-activite.api";
import { handleGatewayError } from "@src/api/utils/handleGatewayError";
import { ReactComponent as Loader } from "@src/assets/loader.svg";
import { storeEntrepriseUpdatedRequest } from "@src/payment/utils/createEntreprises";
import { PaymentElement } from "@stripe/react-stripe-js";
import { ReactNode, useState } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./SocietePaiementStripeForm.module.scss";
import { useSocieteStripePaiement } from "./useSocieteStripePaiement";

type SocietePaiementStripeFormProps<TValue extends FieldValues> = {
  formContent: ReactNode;
  formConfig: UseFormReturn<TValue, unknown, undefined>;
  modePrelevementSelectionne: GatewayPaiement.PaymentMethod;
  siren: string;
  entreprise: GatewayEntreprise.UpdateEntrepriseAControllerRequestType &
    GatewayChangementActivite.ChangerCategoriesOfEntrepriseRequestType;
  paiementIntention: GatewayAdhesion.CreateIntentionPaimentResponseType;
};

export function SocietePaiementStripeForm<TValue extends FieldValues>({
  formContent,
  formConfig,
  modePrelevementSelectionne,
  siren,
  entreprise,
  paiementIntention,
}: SocietePaiementStripeFormProps<TValue>) {
  const { onSubmit: submitStripe } = useSocieteStripePaiement(
    siren,
    entreprise,
    paiementIntention
  );
  const navigate = useNavigate();
  const [isPaiementSubmitting, setIsPaiementSubmitting] = useState(false);

  const handleSubmit = (values: TValue) => {
    if (!paiementIntention.hash)
      return displayErrorToast(
        "Une erreur est survenue lors de la modification des activités"
      );

    setIsPaiementSubmitting(true);
    if (modePrelevementSelectionne === GatewayPaiement.PaymentMethod.TRANSFER) {
      const returnUrl = `/societes/${siren}/informations?paiement=success&hasReachedLimit=${
        (paiementIntention.hasReachedLimit ||
          GatewayEntreprise.AAtteintLeTarif.NON) as string
      }`;
      GatewayChangementActiviteAPI.changeCategoriesOfEntreprise({
        hubspotId: entreprise.hubspotId,
        activites: entreprise.activites,
        activitesAccessoires: entreprise.activitesAccessoires,
        hash: paiementIntention.hash,
        invoiceId: paiementIntention.paiement?.invoiceId ?? null,
        sousCategorieCOBSP: entreprise.sousCategorieCOBSP,
        sousCategoriesIOBSP: entreprise.sousCategoriesIOBSP,
      })
        .then(() => {
          setIsPaiementSubmitting(false);
          storeEntrepriseUpdatedRequest(entreprise);

          displaySucessToast("La société a bien été mise à jour");
          navigate(returnUrl);
        })
        .catch(() => {
          setIsPaiementSubmitting(false);
          displayErrorToast("Une erreur est survenue lors de la mise à jour", {
            canal: "societe-information",
          });
        });
    } else {
      submitStripe()
        .catch(
          handleGatewayError({
            onUnhandled: (error) => {
              console.error(error);
              displayErrorToast(
                "Une erreur est survenue lors du paiement de la cotisation"
              );
            },
          })
        )
        .finally(() => setIsPaiementSubmitting(false));
    }

    return values;
  };

  return (
    <Form formConfig={formConfig} onSubmit={handleSubmit} id={ID_FORM_ADHESION}>
      {formContent}
      {modePrelevementSelectionne ===
        GatewayPaiement.PaymentMethod.TRANSFER && <AdhesionVirementPage />}
      {modePrelevementSelectionne !==
        GatewayPaiement.PaymentMethod.TRANSFER && (
        <FormRow>
          <FormField>
            <PaymentElement id="payment-element" />
          </FormField>
        </FormRow>
      )}
      <Button
        disabled={isPaiementSubmitting}
        className={styles.button}
        type="submit"
        icon={isPaiementSubmitting ? <Loader /> : undefined}
      >
        Valider
      </Button>
    </Form>
  );
}
