import {
  GatewayChangementActivite,
  GatewayEntreprise,
  GatewayInfoSocieteOrias,
} from "@conformite/gateway";
import { zod } from "@lya-protect/lya-protect-form-library/dist/Exports";
import {
  displayErrorToast,
  displaySucessToast,
} from "@lya-protect/lya-protect-form-library/dist/UI";
import { GatewayChangementActiviteAPI } from "@src/api/changement-activite.api";
import { GatewayEntrepriseApi } from "@src/api/entreprise2.api";
import { handleGatewayError } from "@src/api/utils/handleGatewayError";
import { retrieveEntrepriseUpdatedRequest } from "@src/payment/utils/createEntreprises";
import {
  callCreateIntentionPaiementChangementActivite,
  isCategorieAccessoire,
} from "@src/payment/utils/createIntentionPaiement";
import { entrepriseAdresseSchema } from "@src/societes/form/EntrepriseAdresseForm";
import { entrepriseIdentificationSchema } from "@src/societes/form/EntrepriseIdentificationForm";
import { isEqual, partition } from "lodash";
import { useCallback, useEffect, useRef, useState } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";

const ERROR_MESSAGE = "Vous devez choisir au moins une sous-catégorie";

const schema = entrepriseIdentificationSchema
  .omit({ typeSouscripteur: true })
  .merge(entrepriseAdresseSchema)
  .merge(
    zod
      .object({
        id: zod.string(),
        categories: zod
          .array(zod.nativeEnum(GatewayInfoSocieteOrias.Categorie))
          .min(1, {
            message:
              "Vous devez choisir au moins une catégorie à laquelle vous inscrire",
          }),
        coaAccessoire: zod.boolean().optional(),
        cobspAccessoire: zod.boolean().optional(),
        miaAccessoire: zod.boolean().optional(),
        miobspAccessoire: zod.boolean().optional(),
        needMIOBSP: zod.boolean(),
        needCOBSP: zod.boolean(),
        sousCategoriesCOBSP: zod.array(
          zod.nativeEnum(GatewayInfoSocieteOrias.ActiviteBancaire)
        ),
        sousCategoriesMIOBSP: zod.array(
          zod.nativeEnum(GatewayInfoSocieteOrias.ActiviteBancaire)
        ),
        statutOrias: zod
          .nativeEnum(GatewayInfoSocieteOrias.StatutSociete)
          .optional(),
      })
      .merge(
        zod.object({
          consentementEDI: zod.boolean().optional(),
        })
      )
  )
  .refine(
    (data) => (data.needCOBSP ? data.sousCategoriesCOBSP.length > 0 : true),
    {
      message: ERROR_MESSAGE,
      path: ["sousCategoriesCOBSP"],
    }
  )
  .refine(
    (data) => (data.needMIOBSP ? data.sousCategoriesMIOBSP.length > 0 : true),
    {
      message: ERROR_MESSAGE,
      path: ["sousCategoriesMIOBSP"],
    }
  );
export type SocieteInformationFormValues = zod.infer<typeof schema>;

const formValueToEntreprise = (
  values: SocieteInformationFormValues,
  societe: GatewayEntreprise.EntrepriseAControllerResponseType
): [
  GatewayEntreprise.UpdateEntrepriseAControllerRequestType,
  {
    activites: GatewayInfoSocieteOrias.Categorie[];
    activitesAccessoires: GatewayInfoSocieteOrias.Categorie[];
  }
] => {
  const [categorieAccessoire, categorieNonAccessoire] = partition(
    values.categories,
    (categorie) =>
      isCategorieAccessoire(categorie, {
        coaAccessoire: !!values.coaAccessoire,
        miaAccessoire: !!values.miaAccessoire,
        cobspAccessoire: !!values.cobspAccessoire,
        miobspAccessoire: !!values.miobspAccessoire,
      })
  );
  return [
    {
      hubspotId: values.id,
      raisonSociale: values.raisonSociale,
      trancheEffectif: values.trancheEffectif,
      formeJuridique: values.formeJuridique,
      adresse: {
        typeVoie: values.typeVoie,
        libelleVoie: values.libelleVoie,
        numeroVoie: values.numeroVoie,
        complementAdresse: values.complementAdresse ?? "",
        codePostal: values.codePostal,
        ville: values.ville,
        pays: values.pays,
      },
      categorieRepresentation: societe.categorieRepresentation,
      chiffreAffaire: societe.chiffreAffaire,
      organisationProfessionnelle: societe.organisationProfessionnelle,
      sousCategorieCOBSP: values.sousCategoriesCOBSP,
      sousCategoriesIOBSP: values.sousCategoriesMIOBSP,
      numeroOrias: societe.numeroOrias,
      consentementEDI: values.consentementEDI,
    },
    {
      activites: categorieNonAccessoire,
      activitesAccessoires: categorieAccessoire,
    },
  ];
};

export const useSocieteInformation = (
  societe: GatewayEntreprise.EntrepriseAControllerResponseType,
  setEntreprise: (
    entreprise: GatewayEntreprise.EntrepriseAControllerResponseType
  ) => void
) => {
  const [query] = useSearchParams();
  const paiement = query.get("paiement");
  const hasReachedLimit = query.get("hasReachedLimit");
  const { search } = useLocation();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [paiementIntention, setPaiementIntention] =
    useState<GatewayChangementActivite.CreateIntentResponseType>();
  const [newEntreprise, setNewEntreprise] = useState<
    | (GatewayEntreprise.UpdateEntrepriseAControllerRequestType &
        GatewayChangementActivite.ChangerCategoriesOfEntrepriseRequestType)
    | null
  >(null);
  const hasBeenUpdated = useRef(false);

  const updateEntreprise = (
    entrepriseToUpdate: GatewayEntreprise.UpdateEntrepriseAControllerRequestType,
    siren: string
  ) =>
    GatewayEntrepriseApi.updateEntreprise(entrepriseToUpdate)
      .then((updatedEntreprise) => {
        displaySucessToast("La société a bien été mise à jour");
        setEntreprise(updatedEntreprise);
        navigate(`/societes/${siren}`);
      })
      .catch(() => {
        displayErrorToast("Une erreur est survenue lors de la mise à jour", {
          canal: "societe-information",
        });
      })
      .finally(() => {
        setIsLoading(false);
      });

  const updateActivites = useCallback(
    (
      siren: string,
      request: GatewayChangementActivite.ChangerCategoriesOfEntrepriseRequestType
    ) => {
      GatewayChangementActiviteAPI.changeCategoriesOfEntreprise(request)
        .then((updatedEntreprise) => {
          setEntreprise(updatedEntreprise);
          displaySucessToast(
            "Les catégories d'adhésion de la société ont bien été mises à jour"
          );
          navigate(`/societes/${siren}`);
        })
        .catch(() => {
          displayErrorToast(
            "Une erreur est survenue lors de la mise à jour des catégories d'adhésion",
            {
              canal: "societe-information",
            }
          );
        })
        .finally(() => {
          setIsLoading(false);
        });
    },
    [navigate, setEntreprise]
  );

  const handleSubmit = async (values: SocieteInformationFormValues) => {
    setIsLoading(true);
    const categoriesAsChange = !isEqual(
      values.categories,
      societe.categoriesActivites
    );
    const [entrepriseToUpdate, { activites, activitesAccessoires }] =
      formValueToEntreprise(values, societe);

    if (!categoriesAsChange) {
      await updateEntreprise(entrepriseToUpdate, values.siren);
      return;
    }

    const paiementIntent = await callCreateIntentionPaiementChangementActivite(
      values.siren,
      [...activites, ...activitesAccessoires]
    );

    if (!paiementIntent.paiement) {
      updateActivites(values.siren, {
        activites,
        activitesAccessoires,
        hash: paiementIntent.hash,
        hubspotId: entrepriseToUpdate.hubspotId,
        invoiceId: null,
        sousCategorieCOBSP: entrepriseToUpdate.sousCategorieCOBSP,
        sousCategoriesIOBSP: entrepriseToUpdate.sousCategoriesIOBSP,
      });
    } else {
      setPaiementIntention(paiementIntent);
      setNewEntreprise({
        ...entrepriseToUpdate,
        activites,
        activitesAccessoires,
        invoiceId: paiementIntent.paiement.invoiceId,
        hash: paiementIntent.hash,
      });
    }
  };

  useEffect(() => {
    if (paiement === "success" && !hasBeenUpdated.current) {
      hasBeenUpdated.current = true;
      const { updatedEntrepriseParams } = retrieveEntrepriseUpdatedRequest();
      setIsLoading(true);
      GatewayEntrepriseApi.setHasReachedLimit(
        hasReachedLimit as GatewayEntreprise.AAtteintLeTarif
      ).catch(
        handleGatewayError({
          onUnhandled: (e) => {
            console.error(e);
            displayErrorToast(
              "Une erreur est survenue lors de la mise à jour de l'entreprise"
            );
          },
        })
      );
      updateActivites(societe.siren, updatedEntrepriseParams);
    }
  }, [
    updateActivites,
    navigate,
    societe.siren,
    paiement,
    hasReachedLimit,
    setEntreprise,
    search,
    paiementIntention,
  ]);

  return {
    payment: {
      paiementIntention,
      newEntreprise,
    },
    handleSubmit,
    schema,
    isLoading,
  };
};
