import {
  Form,
  InputButtonGroup,
  InputCheckbox,
  InputSelect,
  InputText,
  useFormConfig,
} from "@lya-protect/lya-protect-form-library/dist/FormAPI";
import {
  Button,
  Card,
  FormField,
  FormGrid,
  FormRow,
  FormTitle,
  Toaster,
} from "@lya-protect/lya-protect-form-library/dist/UI";

import {
  GatewayAdresse,
  GatewayEntreprise,
  GatewayInfoSocieteOrias,
} from "@conformite/gateway";
import { MaxWidthContent } from "@src/components/MaxWidthContent/MaxWidthContent";
import { PageWithSidebar } from "@src/components/PageWithSidebar/PageWithSidebar";
import {
  paysOptions,
  typeVoieOptions,
} from "@src/societes/form/EntrepriseAdresseForm";
import {
  formeJuridiqueOptions,
  trancheEffectifOptions,
} from "@src/societes/form/EntrepriseIdentificationForm";
import {
  EntrepriseInfoActiviteFormValues,
  InputTitreAccessoire,
  categorieActiviteLabel,
} from "@src/societes/form/EntrepriseInfoActiviteForm";
import {
  EntrepriseSousCategoriesActiviteFormValues,
  sousCategoriesOptions,
} from "@src/societes/form/EntrepriseSousCategoriesActiviteForm";
import { useEntreprises } from "@src/store/store.entreprise";
import { ReactElement, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { SocietePaiement } from "../paiementCategories/SocietePaiement";
import styles from "./SocieteInformation.module.scss";
import { SocieteInformationActions } from "./SocieteInformationActions";
import { SocieteInformationSidebar } from "./SocieteInformationSidebar";
import {
  SocieteInformationFormValues,
  useSocieteInformation,
} from "./useSocieteInformation";

export const ID_FORM_SOCIETE_INFORMATION = "id-form-societe-information";

type SocieteInformationProps = {
  societe: GatewayEntreprise.EntrepriseAControllerResponseType;
  setEntreprise: (
    entreprise: GatewayEntreprise.EntrepriseAControllerResponseType
  ) => void;
};
export function SocieteInformation({
  societe,
  setEntreprise,
}: SocieteInformationProps) {
  const { schema, handleSubmit, isLoading, payment } = useSocieteInformation(
    societe,
    setEntreprise
  );
  const [showActivities, setShowActivities] = useState<
    "no" | "warned" | "true"
  >("no");

  const {
    hubspotId,
    raisonSociale,
    adresse,
    siren,
    formeJuridique,
    trancheEffectif,
    categoriesActivites,
    categoriesActivitesAccessoires,
    consentementEDI,
    sousCategorieCOBSP,
    sousCategoriesIOBSP,
  } = societe;
  const formConfig = useFormConfig<typeof schema, SocieteInformationFormValues>(
    {
      schema,
      defaultValues: {
        ...adresse,
        complementAdresse: adresse.complementAdresse ?? "",
        id: hubspotId,
        raisonSociale,
        siren,
        formeJuridique,
        trancheEffectif,
        categories: [...categoriesActivites, ...categoriesActivitesAccessoires],
        coaAccessoire: categoriesActivitesAccessoires.includes(
          GatewayInfoSocieteOrias.Categorie.COA
        ),
        cobspAccessoire: categoriesActivitesAccessoires.includes(
          GatewayInfoSocieteOrias.Categorie.COBSP
        ),
        miaAccessoire: categoriesActivitesAccessoires.includes(
          GatewayInfoSocieteOrias.Categorie.MIA
        ),
        miobspAccessoire: categoriesActivitesAccessoires.includes(
          GatewayInfoSocieteOrias.Categorie.MIOBSP
        ),
        needCOBSP: categoriesActivites.includes(
          GatewayInfoSocieteOrias.Categorie.COBSP
        ),
        needMIOBSP: categoriesActivites.includes(
          GatewayInfoSocieteOrias.Categorie.MIOBSP
        ),
        sousCategoriesCOBSP: sousCategorieCOBSP,
        sousCategoriesMIOBSP: sousCategoriesIOBSP,
        consentementEDI,
      },
    }
  );

  const categoriesWatched = formConfig.watch("categories");
  const [needCOBSP, needMIOBSP] = formConfig.watch(["needCOBSP", "needMIOBSP"]);

  useEffect(() => {
    formConfig.setValue(
      "needCOBSP",
      !!categoriesWatched?.includes(GatewayInfoSocieteOrias.Categorie.COBSP)
    );
    formConfig.setValue(
      "needMIOBSP",
      !!categoriesWatched?.includes(GatewayInfoSocieteOrias.Categorie.MIOBSP)
    );
  }, [needCOBSP, needMIOBSP, categoriesWatched, formConfig]);

  const categoriesOptions: {
    value: GatewayInfoSocieteOrias.Categorie;
    label: string;
    description?: ReactElement;
  }[] = useMemo(
    () => [
      {
        value: GatewayInfoSocieteOrias.Categorie.COA,
        label: categorieActiviteLabel[GatewayInfoSocieteOrias.Categorie.COA],
        description: categoriesWatched?.includes(
          GatewayInfoSocieteOrias.Categorie.COA
        ) ? (
          <InputTitreAccessoire name="coaAccessoire" />
        ) : undefined,
      },
      {
        value: GatewayInfoSocieteOrias.Categorie.MIA,
        label: categorieActiviteLabel[GatewayInfoSocieteOrias.Categorie.MIA],
        description: categoriesWatched?.includes(
          GatewayInfoSocieteOrias.Categorie.MIA
        ) ? (
          <InputTitreAccessoire name="miaAccessoire" />
        ) : undefined,
      },
      {
        value: GatewayInfoSocieteOrias.Categorie.COBSP,
        label: categorieActiviteLabel[GatewayInfoSocieteOrias.Categorie.COBSP],
        description: categoriesWatched?.includes(
          GatewayInfoSocieteOrias.Categorie.COBSP
        ) ? (
          <InputTitreAccessoire name="cobspAccessoire" />
        ) : undefined,
      },
      {
        value: GatewayInfoSocieteOrias.Categorie.MIOBSP,
        label: categorieActiviteLabel[GatewayInfoSocieteOrias.Categorie.MIOBSP],
        description: categoriesWatched?.includes(
          GatewayInfoSocieteOrias.Categorie.MIOBSP
        ) ? (
          <InputTitreAccessoire name="miobspAccessoire" />
        ) : undefined,
      },
    ],
    [categoriesWatched]
  );

  if (payment.paiementIntention?.paiement && payment.newEntreprise)
    return (
      <SocietePaiement
        siren={societe.siren}
        paiementIntention={{
          ...payment.paiementIntention,
          paiement: payment.paiementIntention.paiement,
          idsEntreprisesNonAdherentes: [],
        }}
        entreprise={payment.newEntreprise}
      />
    );
  return (
    <FormGrid>
      <Form
        formConfig={formConfig}
        onSubmit={handleSubmit}
        id={ID_FORM_SOCIETE_INFORMATION}
      >
        <FormTitle>
          <h1>Informations générales</h1>
        </FormTitle>
        <FormRow>
          <FormField>
            <InputText<SocieteInformationFormValues>
              label="Siren"
              name="siren"
              required
              disabled
            />
          </FormField>
        </FormRow>
        <FormRow>
          <FormField>
            <InputText<SocieteInformationFormValues>
              label="Raison sociale"
              name="raisonSociale"
              required
            />
          </FormField>
        </FormRow>
        <FormRow>
          <FormField>
            <InputSelect<
              SocieteInformationFormValues,
              GatewayEntreprise.TrancheEffectif
            >
              name="trancheEffectif"
              label="Tranche effectif"
              placeholder="Sélectionner"
              options={trancheEffectifOptions}
              required
            />
          </FormField>
        </FormRow>
        <FormRow>
          <FormField>
            <InputSelect<
              SocieteInformationFormValues,
              GatewayEntreprise.FormeJuridique
            >
              name="formeJuridique"
              label="Forme juridique"
              placeholder="Sélectionner"
              options={formeJuridiqueOptions}
              required
            />
          </FormField>
        </FormRow>
        <FormRow className={styles.activites}>
          <FormField>
            {showActivities === "true" && (
              <InputButtonGroup<
                EntrepriseInfoActiviteFormValues,
                true,
                GatewayInfoSocieteOrias.Categorie
              >
                label={
                  <b className={styles.label}>Vos catégories d&apos;adhésion</b>
                }
                options={categoriesOptions}
                name="categories"
                variant="outline-pop"
                size="large"
                isMultiple
              />
            )}
            {showActivities !== "true" && (
              <div>
                <b className={styles.label}>Vos catégories d&apos;adhésion</b>
                <ul>
                  {[
                    ...societe.categoriesActivites,
                    ...societe.categoriesActivitesAccessoires,
                  ].map((cat) => (
                    <li key={cat}>{categorieActiviteLabel[cat]}</li>
                  ))}
                </ul>
                {showActivities === "warned" && (
                  <p>
                    L&apos;ajout de catégorie(s) peut entrainer le règlement
                    d&apos;un complément de cotisation. Pour continuer, cliquez
                    sur
                    <Button
                      variant="text"
                      onClick={() => setShowActivities("true")}
                      className={styles.warningButton}
                    >
                      “Confirmer”.
                    </Button>
                  </p>
                )}

                {showActivities === "no" && (
                  <Button onClick={() => setShowActivities("warned")}>
                    Modifier
                  </Button>
                )}
              </div>
            )}
          </FormField>
        </FormRow>
        {(needMIOBSP || needCOBSP) && (
          <FormRow>
            <FormField>
              {needCOBSP && (
                <FormRow>
                  <FormField>
                    <InputButtonGroup<
                      EntrepriseSousCategoriesActiviteFormValues,
                      true,
                      GatewayInfoSocieteOrias.ActiviteBancaire
                    >
                      options={sousCategoriesOptions}
                      name="sousCategoriesCOBSP"
                      label={
                        <b>
                          Sélectionnez au moins une sous-catégorie à votre
                          activité COBSP :
                        </b>
                      }
                      isMultiple
                      variant="outline-pop"
                      size="large"
                    />
                  </FormField>
                </FormRow>
              )}

              {needMIOBSP && (
                <FormRow>
                  <FormField>
                    <InputButtonGroup<
                      EntrepriseSousCategoriesActiviteFormValues,
                      true,
                      GatewayInfoSocieteOrias.ActiviteBancaire
                    >
                      options={sousCategoriesOptions}
                      name="sousCategoriesMIOBSP"
                      label={
                        <b>
                          Sélectionnez au moins une sous-catégorie à votre
                          activité MIOBSP :
                        </b>
                      }
                      variant="outline-pop"
                      size="large"
                      isMultiple
                    />
                  </FormField>
                </FormRow>
              )}
            </FormField>
          </FormRow>
        )}

        <h2 className={styles.subtitle}>Adresse</h2>
        <FormRow>
          <FormField>
            <InputSelect<SocieteInformationFormValues, GatewayAdresse.TypeVoie>
              isSearchable
              name="typeVoie"
              label="Type de voie"
              placeholder="Sélectionner"
              options={typeVoieOptions}
              required
            />
          </FormField>
        </FormRow>
        <FormRow>
          <FormField>
            <InputText<SocieteInformationFormValues>
              label="Libellé de la voie"
              name="libelleVoie"
              required
            />
          </FormField>
        </FormRow>
        <FormRow>
          <FormField>
            <InputText<SocieteInformationFormValues>
              label="Numéro de rue"
              name="numeroVoie"
            />
          </FormField>
        </FormRow>
        <FormRow>
          <FormField>
            <InputText<SocieteInformationFormValues>
              label="Complément d'adresse"
              name="complementAdresse"
            />
          </FormField>
        </FormRow>
        <FormRow>
          <FormField>
            <InputSelect<SocieteInformationFormValues, GatewayAdresse.Pays>
              name="pays"
              label="Pays"
              placeholder="Sélectionner"
              options={paysOptions}
              required
            />
          </FormField>
        </FormRow>
        <FormRow>
          <FormField size="quarter">
            <InputText<SocieteInformationFormValues>
              label="Code postal"
              name="codePostal"
              required
            />
          </FormField>
          <FormField size="three-quarter">
            <InputText<SocieteInformationFormValues>
              label="Ville"
              name="ville"
              required
            />
          </FormField>
        </FormRow>
        {[...categoriesActivites, ...categoriesActivitesAccessoires].includes(
          GatewayInfoSocieteOrias.Categorie.COA
        ) && (
          <FormRow>
            <FormField>
              <InputCheckbox<SocieteInformationFormValues>
                name="consentementEDI"
                label="Je souhaite partager mes informations avec la plateforme EDI Conformité"
              />
            </FormField>
          </FormRow>
        )}
        {formConfig.formState.isDirty && (
          <SocieteInformationActions
            onCancel={formConfig.reset}
            isLoading={isLoading}
          />
        )}
        <Toaster
          canal={ID_FORM_SOCIETE_INFORMATION}
          position={{
            vertical: "bottom",
            horizontal: "center",
          }}
          className={styles.toaster}
        />
      </Form>
    </FormGrid>
  );
}

export function SocieteInformationContainer() {
  const { entreprisesBySiren, setEntreprise } = useEntreprises();
  const { id } = useParams<{ id: string }>();
  const societe = id && entreprisesBySiren ? entreprisesBySiren[id] : undefined;

  return (
    <PageWithSidebar sidebar={<SocieteInformationSidebar />}>
      <MaxWidthContent className={styles.page} size="big">
        {societe && (
          <Card>
            <SocieteInformation
              societe={societe}
              setEntreprise={setEntreprise}
            />
          </Card>
        )}
      </MaxWidthContent>
    </PageWithSidebar>
  );
}
