import {
  GatewayChangementActivite,
  GatewayEntreprise,
  GatewayInfoSocieteOrias,
} from "@conformite/gateway";
import { userSession } from "@src/session/UserSession";

export class GatewayChangementActiviteAPI {
  public static async createPaiementIntent(
    siren: string,
    activites: GatewayInfoSocieteOrias.Categorie[]
  ) {
    const resp =
      await userSession.axiosInstance.post<GatewayChangementActivite.CreateIntentResponseType>(
        "/adhesion/changement-activite/createPaiementIntent",
        { siren, activites }
      );

    return resp.data;
  }

  public static async changeCategoriesOfEntreprise(
    request: GatewayChangementActivite.ChangerCategoriesOfEntrepriseRequestType
  ) {
    const res =
      await userSession.axiosInstance.patch<GatewayEntreprise.EntrepriseAControllerResponseType>(
        `/adhesion/changement-activite`,
        request
      );
    return res.data;
  }
}
