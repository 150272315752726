import {
  GatewayAdhesion,
  GatewayEntreprise,
  GatewayInfoSocieteOrias,
} from "@conformite/gateway";
import { AdhesionData } from "@src/adhesion/AdhesionData/adhesionData";
import { AdhesionDataEntreprisesItem } from "@src/adhesion/AdhesionData/adhesionDataEntreprises";
import { GatewayAdhesion2Api } from "@src/api/adhesion2.api";
import { GatewayChangementActiviteAPI } from "@src/api/changement-activite.api";
import { RenouvellementData } from "@src/renouvellement/steps/RenouvellementData";
import { partition } from "lodash";

type MatriceAccessoire = {
  coaAccessoire: boolean;
  miaAccessoire: boolean;
  cobspAccessoire: boolean;
  miobspAccessoire: boolean;
};

export function isCategorieAccessoire(
  categorie: GatewayInfoSocieteOrias.Categorie,
  matrice: MatriceAccessoire
) {
  switch (categorie) {
    case GatewayInfoSocieteOrias.Categorie.COBSP:
      return matrice.cobspAccessoire;
    case GatewayInfoSocieteOrias.Categorie.MIOBSP:
      return matrice.miobspAccessoire;
    case GatewayInfoSocieteOrias.Categorie.MIA:
      return matrice.miaAccessoire;
    case GatewayInfoSocieteOrias.Categorie.COA:
      return matrice.coaAccessoire;
    default:
      throw new Error("Categorie non gérée");
  }
}

export function getEntrepriseAControllerEIRenouvellement(
  data: RenouvellementData
): GatewayAdhesion.CreateIntentionPaimentEIRequestType {
  const entreprise =
    data.entreprisesAControler[data.resume.entreprisePrincipale.siren];

  return {
    numeroOrias: entreprise.numeroOrias,
    chiffreAffaire: entreprise.chiffreAffaire as number,
    categoriesActivites: entreprise.categoriesActivites,
    categoriesActivitesAccessoires: entreprise.categoriesActivitesAccessoires,
    categorieRepresentation: entreprise.categorieRepresentation,
    organisationProfessionnelle: entreprise.organisationProfessionnelle,
    sousCategorieCOBSP: entreprise.sousCategorieCOBSP,
    sousCategoriesIOBSP: entreprise.sousCategoriesIOBSP,
    coupon: data.coupon,
    consentementEDI: entreprise.consentementEDI,
  };
}

export function getEntrepriseAControllerEI(
  data: AdhesionData
): GatewayAdhesion.CreateIntentionPaimentEIRequestType {
  const [categorieAccessoire, categorieNonAccessoire] = partition(
    data.informationsActivite.categories || [],
    (categorie) =>
      isCategorieAccessoire(categorie, {
        coaAccessoire: !!data.informationsActivite.coaAccessoire,
        miaAccessoire: !!data.informationsActivite.miaAccessoire,
        cobspAccessoire: !!data.informationsActivite.cobspAccessoire,
        miobspAccessoire: !!data.informationsActivite.miobspAccessoire,
      })
  );

  return {
    numeroOrias: undefined,
    chiffreAffaire: data.informationsComplementaires
      .chiffreAffairesEnEurosNMoins1 as number,
    categoriesActivites: categorieNonAccessoire,
    categoriesActivitesAccessoires: categorieAccessoire,
    categorieRepresentation: data.informationsComplementaires
      .categorieRepresentation as GatewayInfoSocieteOrias.CategorieRepresentation,
    organisationProfessionnelle: data.organisationsProfessionnelles,
    sousCategorieCOBSP: data.informationsActivite.sousCategoriesCOBSP,
    sousCategoriesIOBSP: data.informationsActivite.sousCategoriesMIOBSP,
    coupon: data.coupon,
    consentementEDI: data.informationsComplementaires.consentementEDI,
  };
}

export function getEntreprisesAControllerGroupeRenouvellement(
  data: RenouvellementData
): GatewayAdhesion.AdhesionCreationEntrepriseAControllerRequestType[] {
  return Object.values(data.entreprisesAControler).map((e) => {
    return {
      siren: e.siren,
      raisonSociale: e.raisonSociale,
      numeroOrias: e.numeroOrias,
      adresse: e.adresse,
      formeJuridique: e.formeJuridique,
      trancheEffectif: e.trancheEffectif,
      chiffreAffaire: e.chiffreAffaire as number,
      categoriesActivites: e.categoriesActivites,
      categoriesActivitesAccessoires: e.categoriesActivitesAccessoires,
      categorieRepresentation: e.categorieRepresentation,
      organisationProfessionnelle: e.organisationProfessionnelle,
      sousCategorieCOBSP: e.sousCategorieCOBSP,
      sousCategoriesIOBSP: e.sousCategoriesIOBSP,
      consentementEDI: e.consentementEDI,
    };
  });
}

export function getEntreprisesAControllerGroupe(
  data: AdhesionData
): GatewayAdhesion.CreationEntrepriseAControllerType[] {
  return data.societesRattachees.map((s) => {
    const [categorieAccessoire, categorieNonAccessoire] = partition(
      s.categoriesOrias || [],
      (categorie) =>
        isCategorieAccessoire(categorie, {
          coaAccessoire: !!s.coaAccessoire,
          miaAccessoire: !!s.miaAccessoire,
          cobspAccessoire: !!s.cobspAccessoire,
          miobspAccessoire: !!s.miobspAccessoire,
        })
    );
    return {
      adresse: {
        codePostal: s.codePostal,
        libelleVoie: s.libelleVoie,
        numeroVoie: s.numeroVoie,
        pays: s.pays,
        typeVoie: s.typeVoie,
        ville: s.ville,
        complementAdresse: s.complementAdresse,
      },
      siren: s.siren,
      raisonSociale: s.raisonSociale,
      numeroOrias: s.numeroOrias,
      formeJuridique: s.formeJuridique,
      trancheEffectif: s.trancheEffectif,
      chiffreAffaire: s.chiffreAffaire,
      categoriesActivites: categorieNonAccessoire,
      categoriesActivitesAccessoires: categorieAccessoire,
      categorieRepresentation: s.categorieRepresentation,
      organisationProfessionnelle: s.organisationPro,
      sousCategorieCOBSP: s.sousCategorieCobsp,
      sousCategoriesIOBSP: s.sousCategorieMiobsp,
      consentementEDI: s.consentementEDI,
    } as GatewayAdhesion.CreationEntrepriseAControllerType;
  });
}

export function callCreateIntentionPaiementAdhesion(data: AdhesionData) {
  if (
    data.typeEntreprise.type ===
    GatewayEntreprise.TypeAdhesion.SOCIETE_INDIVIDUELLE
  ) {
    const entrepriseAController = getEntrepriseAControllerEI(data);
    return GatewayAdhesion2Api.createIntentionPaiementEI(entrepriseAController);
  }
  const entreprisesAController = getEntreprisesAControllerGroupe(data);
  return GatewayAdhesion2Api.createIntentionPaiementGroupe(
    data.typeEntreprise.type as GatewayEntreprise.TypeAdhesionActive,
    entreprisesAController,
    data.coupon
  );
}

export function callCreateIntentionPaiementRenouvellement(
  data: RenouvellementData
) {
  if (
    data.typeAdhesion === GatewayEntreprise.TypeAdhesion.SOCIETE_INDIVIDUELLE
  ) {
    const entrepriseAController =
      getEntrepriseAControllerEIRenouvellement(data);
    return GatewayAdhesion2Api.createIntentionPaiementRenouvellementEI(
      entrepriseAController
    );
  }
  const entreprisesAController =
    getEntreprisesAControllerGroupeRenouvellement(data);
  return GatewayAdhesion2Api.createIntentionPaiementRenouvellementGroupe(
    entreprisesAController,
    data.typeAdhesion as GatewayEntreprise.TypeAdhesionActive,
    data.coupon
  );
}

export function getEntrepriseAControllerAdhesionEntreprise(
  societeRattachee: AdhesionDataEntreprisesItem
): GatewayAdhesion.CreationEntrepriseAControllerType[] {
  const [categorieAccessoire, categorieNonAccessoire] = partition(
    societeRattachee.categoriesOrias || [],
    (categorie) =>
      isCategorieAccessoire(categorie, {
        coaAccessoire: !!societeRattachee.coaAccessoire,
        miaAccessoire: !!societeRattachee.miaAccessoire,
        cobspAccessoire: !!societeRattachee.cobspAccessoire,
        miobspAccessoire: !!societeRattachee.miobspAccessoire,
      })
  );
  return [
    {
      adresse: {
        codePostal: societeRattachee.codePostal,
        libelleVoie: societeRattachee.libelleVoie,
        numeroVoie: societeRattachee.numeroVoie,
        pays: societeRattachee.pays,
        typeVoie: societeRattachee.typeVoie,
        ville: societeRattachee.ville,
        complementAdresse: societeRattachee.complementAdresse,
      },
      siren: societeRattachee.siren,
      raisonSociale: societeRattachee.raisonSociale,
      numeroOrias: societeRattachee.numeroOrias,
      formeJuridique: societeRattachee.formeJuridique,
      trancheEffectif: societeRattachee.trancheEffectif,
      chiffreAffaire: societeRattachee.chiffreAffaire,
      categoriesActivites: categorieNonAccessoire,
      categoriesActivitesAccessoires: categorieAccessoire,
      categorieRepresentation: societeRattachee.categorieRepresentation,
      organisationProfessionnelle: societeRattachee.organisationPro,
      sousCategorieCOBSP: societeRattachee.sousCategorieCobsp,
      sousCategoriesIOBSP: societeRattachee.sousCategorieMiobsp,
    } as GatewayAdhesion.CreationEntrepriseAControllerType,
  ];
}

export function callCreateIntentionPaiementAdhesionEntreprise(
  typeAdhesion: GatewayEntreprise.TypeAdhesionActive,
  data: AdhesionDataEntreprisesItem
) {
  const entreprisesAController =
    getEntrepriseAControllerAdhesionEntreprise(data);

  return GatewayAdhesion2Api.createIntentionPaiementGroupe(
    typeAdhesion,
    entreprisesAController,
    data.coupon
  );
}

export function callCreateIntentionPaiementChangementActivite(
  siren: string,
  activites: GatewayInfoSocieteOrias.Categorie[]
) {
  return GatewayChangementActiviteAPI.createPaiementIntent(siren, activites);
}
