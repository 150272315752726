import {
  GatewayAdhesion,
  GatewayChangementActivite,
  GatewayEntreprise,
  GatewayPaiement,
} from "@conformite/gateway";
import { zod } from "@lya-protect/lya-protect-form-library/dist/Exports";
import {
  InputButtonGroup,
  InputCheckbox,
  useFormConfig,
} from "@lya-protect/lya-protect-form-library/dist/FormAPI";
import {
  Card,
  FormField,
  FormRow,
  displayErrorToast,
} from "@lya-protect/lya-protect-form-library/dist/UI";
import { ParcoursAdhesionNextAction } from "@src/adhesion/AdhesionActions/ParcoursAdhesionNextAction";
import { GatewayPaymentApi } from "@src/api/payment.api";
import { handleGatewayError } from "@src/api/utils/handleGatewayError";
import { InfoCard } from "@src/components/InfoCard/InfoCard";
import { LoadingPage } from "@src/components/LoadingPage/LoadingPage";
import { toEuro } from "@src/helper/currency.helper";
import {
  LocalStorageKeys,
  localStorageHelper,
} from "@src/helper/localStorage.helper";
import { ParcoursFullPageTemplate } from "@src/parcours/Template/ParcoursFullPageTemplate";
import { usePaymentButtonLabel } from "@src/societes/form/EntreprisePaymentForm";
import { Elements } from "@stripe/react-stripe-js";
import { StripeElementsOptions, loadStripe } from "@stripe/stripe-js";
import { useCallback, useEffect, useMemo, useState } from "react";
import { SocietePaiementStripeForm } from "./SocietePaiementStripeForm";

const stripePromise = loadStripe(import.meta.env.VITE_STRIPE_PUBLIC);

export function getPaiementMethodOptions(amount: number) {
  if (amount > 10000) {
    return [
      {
        label: "Carte bancaire",
        value: GatewayPaiement.PaymentMethod.CARD,
      },
      {
        label: "Virement bancaire",
        value: GatewayPaiement.PaymentMethod.TRANSFER,
      },
    ];
  }
  return [
    {
      label: "Carte bancaire",
      value: GatewayPaiement.PaymentMethod.CARD,
    },
    {
      label: "Prélévement SEPA",
      value: GatewayPaiement.PaymentMethod.SEPA,
    },
    {
      label: "Virement bancaire",
      value: GatewayPaiement.PaymentMethod.TRANSFER,
    },
  ];
}

interface SocietePaiementProps {
  siren: string;
  paiementIntention: GatewayAdhesion.CreateIntentionPaimentResponseType;
  entreprise: GatewayEntreprise.UpdateEntrepriseAControllerRequestType &
    GatewayChangementActivite.ChangerCategoriesOfEntrepriseRequestType;
}

export function SocietePaiement({
  siren,
  paiementIntention,
  entreprise,
}: SocietePaiementProps) {
  const options = useMemo<StripeElementsOptions>(
    () => ({ clientSecret: paiementIntention?.paiement?.clientSecret }),
    [paiementIntention]
  );

  const [key, setKey] = useState(0);

  const schema = zod.object({
    sengageEnRepresentant: zod
      .boolean()
      .optional()
      .refine((val) => {
        return val === true;
      }, "Vous devez cocher cette case pour continuer"),
    modePrelevement: zod.nativeEnum(GatewayPaiement.PaymentMethod),
  });

  const formConfig = useFormConfig<typeof schema, AdhesionPaiementFormValues>({
    schema,
    defaultValues: { modePrelevement: GatewayPaiement.PaymentMethod.CARD },
  });

  type AdhesionPaiementFormValues = zod.infer<typeof schema>;

  const modePrelevementSelectionne = formConfig.watch("modePrelevement");

  const updateinvoice = useCallback(
    async (modePrelevement: GatewayPaiement.PaymentMethod) => {
      if (!paiementIntention?.paiement?.invoiceId) return;
      try {
        await GatewayPaymentApi.updateInvoice(
          paiementIntention.paiement.invoiceId,
          {
            paymentMethod: modePrelevement,
          }
        );
        setKey((prev) => prev + 1);
      } catch (error) {
        handleGatewayError({
          onUnhandled: () => displayErrorToast("Une erreur est survenue"),
        })(error);
      }
    },
    [paiementIntention]
  );

  useEffect(() => {
    localStorageHelper.setItem(
      LocalStorageKeys.CREATION_ENTREPRISES_TYPE_PAIEMENT,
      modePrelevementSelectionne === GatewayPaiement.PaymentMethod.CARD
        ? "CB"
        : "AUTRES"
    );
    if (modePrelevementSelectionne !== GatewayPaiement.PaymentMethod.TRANSFER) {
      updateinvoice(modePrelevementSelectionne).catch(console.error);
    }
  }, [modePrelevementSelectionne, updateinvoice]);

  const nextLabel = usePaymentButtonLabel(modePrelevementSelectionne);

  if (!paiementIntention?.paiement?.intentId) return <LoadingPage />;

  return (
    <ParcoursFullPageTemplate
      title="Paiement de l'adhésion"
      actions={
        <ParcoursAdhesionNextAction type="submit" icon={undefined}>
          {nextLabel}
        </ParcoursAdhesionNextAction>
      }
    >
      <FormRow>
        <FormField>
          <Card variant="accent">
            <h4>
              <b>
                {`${"Le montant total des adhésions"} pour l'année ${new Date().getFullYear()} s'élève à `}
                <span>
                  {paiementIntention.paiement.montant
                    ? toEuro(paiementIntention.paiement.montant, {
                        hideUselessFractionDigits: true,
                      })
                    : "-"}
                </span>
                {paiementIntention.paiement.subtotal !==
                  paiementIntention.paiement.montant && (
                  <span>
                    {paiementIntention.paiement.subtotal
                      ? toEuro(paiementIntention.paiement.subtotal, {
                          hideUselessFractionDigits: true,
                        })
                      : "-"}
                  </span>
                )}
              </b>
            </h4>
          </Card>
        </FormField>
      </FormRow>
      <FormRow>
        <FormField>
          {options.clientSecret && paiementIntention.paiement.intentId && (
            <Elements key={key} stripe={stripePromise} options={options}>
              <SocietePaiementStripeForm<AdhesionPaiementFormValues>
                entreprise={entreprise}
                siren={siren}
                paiementIntention={paiementIntention}
                formConfig={formConfig}
                modePrelevementSelectionne={modePrelevementSelectionne}
                formContent={
                  <>
                    <FormRow>
                      <FormField>
                        <InputCheckbox<AdhesionPaiementFormValues>
                          name="sengageEnRepresentant"
                          label="Je m'engage en tant que représentant des sociétés de ce groupe."
                        />
                      </FormField>
                    </FormRow>

                    <FormRow>
                      <FormField>
                        <InputButtonGroup<
                          AdhesionPaiementFormValues,
                          false,
                          GatewayPaiement.PaymentMethod
                        >
                          name="modePrelevement"
                          variant="outline-pop"
                          options={getPaiementMethodOptions(
                            paiementIntention.paiement.montant
                          )}
                        />
                      </FormField>
                    </FormRow>

                    {modePrelevementSelectionne ===
                      GatewayPaiement.PaymentMethod.TRANSFER && (
                      <FormRow>
                        <FormField>
                          <InfoCard type="warning">
                            Le paiement par virement nécessite une action
                            manuelle de notre part, ce qui rallonge le délai de
                            traitement de votre dossier.
                          </InfoCard>
                        </FormField>
                      </FormRow>
                    )}
                    <FormRow>
                      <FormField>
                        <hr />
                      </FormField>
                    </FormRow>
                  </>
                }
              />
            </Elements>
          )}
        </FormField>
      </FormRow>
    </ParcoursFullPageTemplate>
  );
}
