import {
  ControleQuinquennalAxeStatus as ControleQuinquennalAxeStatusEntity,
  ControleQuinquennalAxeType as ControleQuinquennalAxeTypeEntity,
  ControleQuinquennalStatus as ControleQuinquennalStatusEntity,
} from '../service/controle-quinquennal.definition';

export type ControleQuinquennal = {
  id: string;
  idEntreprise: string;
  idEntreprisePrincipale: string;
  millesime: number;
  status: ControleQuinquennalStatus;
  dateDepot: Date | null;
  dateCreation: Date;
  idTicket: string;
};
export type ControleQuinquennalCreation = Omit<
  ControleQuinquennal,
  'id' | 'status' | 'dateDepot'
>;
export type ControleQuinquennalWithCompletion = ControleQuinquennal & {
  hasRemediations: boolean;
  completion: number;
};
export type ControleQuinquennalWithAxes = ControleQuinquennal & {
  controleQuinquennalAxes: ControleQuinquennalAxeWithNumberOfFiles[];
  datePremiereRemediation: Date | null;
  dateRapportFinal: Date | null;
};

export type ControleQuinquennalAxeType = ControleQuinquennalAxeTypeEntity;
export const ControleQuinquennalAxeType = ControleQuinquennalAxeTypeEntity;
export type ControleQuinquennalStatus = ControleQuinquennalStatusEntity;
export const ControleQuinquennalStatus = ControleQuinquennalStatusEntity;
export type ControleQuinquennalAxeStatus = ControleQuinquennalAxeStatusEntity;
export const ControleQuinquennalAxeStatus = ControleQuinquennalAxeStatusEntity;

export type ControleQuinquennalAxe = {
  idAxe: string;
  type: ControleQuinquennalAxeType;
  idControleQuinquennal: string;
  isComplet: boolean;
  status: ControleQuinquennalAxeStatus;
};
export type ControleQuinquennalAxeWithNumberOfFiles = ControleQuinquennalAxe & {
  numberOfFiles: number;
  hasRemediation: boolean;
};
export type ControleQuinquennalAxeCreation = Omit<
  ControleQuinquennalAxe,
  'idAxe' | 'isComplet' | 'status' | 'idControleQuinquennal'
>;
export type ControleQuinquennalAxeUpdate = {
  isComplet: boolean;
  idFiles: string[];
};

export type ControleQuinquennalAxeCreationAndDeletion = {
  creation: ControleQuinquennalAxeCreation[];
  idAxesDeletion: string[];
};

export type ControleQuinquennalAxeWithFiles = ControleQuinquennalAxe & {
  controleQuinquennalAxeFiles: ControleQuinquennalAxeFile[];
};
export type ControleQuinquennalAxeFile = {
  idFile: string;
  pathname: string;
  filename: string;
  isReadonly: boolean;
};
export type ControleQuinquennalRemediationRequestType = {
  description: string;
  idFiles: string[];
};
export type ControleQuinquennalRemediationFileResponseType = {
  idFile: string;
  pathname: string;
  filename: string;
};
export type ControleQuinquennalRemediationResponseType = {
  description: string;
  creationDate: string;
  id: string;
};

export type ControleQuinquennalRemediationWithFileResponseType =
  ControleQuinquennalRemediationResponseType & {
    files: ControleQuinquennalRemediationFileResponseType[];
  };
export type ControleQuinquennalRapport = {
  idFile: string;
  pathname: string;
  filename: string;
};
